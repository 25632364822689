// .navbar-brand{
//     background-image: url("/ortho-logo-black.png");
// }

.ortho-logo{
    align-self: center;
    background-size: contain;
    background-image: url("/assets/images/econn-logo.png");
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    width: 290px;   
    height: 26px;
    margin-bottom: 0;
}

.nav-link{
    font-family: 'Overpass',sans-serif !important;
    font-size: 16px !important;
    color: #212529;
    padding: 10px 12px !important;
    font-weight: bold;
}

.nav-link:hover,.nav-link:focus,.selected{
    color: #cf2f44 !important;
    font-family: 'Overpass',sans-serif !important;
    font-size: 16px !important;
    padding: 10px 12px !important;
    font-weight: bold;
}

.navbar{
    background:#ffffff
}