:root {
    --gray: rgb(196, 194, 194);
  }

.colLevel1 {
    border: solid;
    border-radius: 1px;
    text-align: right;
}

.colHour {
    text-align: center;
    border: solid black;
    background-color: var(--gray);
    border-radius: 1px;
    border-width: 1px;
    height: 6vh;
}

.weekDayDate {
    text-align: center;
    border: solid black;
    background-color: var(--gray);
    border-radius: 1px;
    border-width: 1px;
    height: 10vh;
}

.ABColBuffer {
    text-align: center;
    border: solid black;
    background-color: var(--gray);
    border-radius: 1px;
    border-width: 1px;
    height: 6vh;
}

.weekDay {
    text-align: center;
}

.dataFileData {
    text-align: center;
    border: solid rgb(53, 51, 51);
    border-radius: 1px;
    border-width: 1px;
    height: 144vh;
}

.white-border {
    border: solid white;
    border-radius: 1px;
    border-width: 1px 0.5px;
}

.status-complete {
    background-color: #00c853;
}

.status-complete:hover {
    background-color: #33b86a;
}

.status-error {
    background-color: #c22c2ce8;
}

.status-error:hover {
    background-color: #a32121e8;
}

/*
.status-progress {
    background-color: #3f51b5;
}

.status-progress:hover {
    background-color: #2d3fa3;
}
*/
.status-start {
    background-color: rgb(255, 196, 0);
}

.status-start:hover {
    background-color: rgb(226, 176, 9);
}

.status-not-loaded {
    background-color: rgb(156, 154, 154);
}

.status-not-loaded:hover {
    background-color: rgb(136, 133, 133);
}

.buttonLoadData {
    height: 53px;
}

.legend {
    text-align: center;
    margin-bottom: 5px;
    border-radius: 5px;
}