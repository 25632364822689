.noData {
    text-align: center;
    text-align: center !important;
    width: 100% !important;
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.noDataDiv {
    flex: 1 1 auto;
    padding: 1rem 1rem;
    overflow: auto;
}