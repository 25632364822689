.bold{
    font-weight: 500;
}

.icon{
    cursor: pointer;
}

.card-body{
    overflow: auto;
}