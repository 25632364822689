.error-container {
    text-align: center;
    margin-top: 5vh;
}

.header-logo {
    align-self: center;
    background-size: cover;
    background-image: url("/assets/images/header-logo.png");
    background-repeat: no-repeat;
    content: '';
    color: whitesmoke;
    display: inline-block;
    width:95%;
    height: 92px;
}

.header-text {
    text-align: left;
    font-size: 40px;
    color: white;
    padding-left: 2%;
    padding-top: 15px;
}

.error-conatiner {
    font-family: Tahoma,Verdana,Arial;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    height: 55vh;
}

.error-text {
    color: #CC0000;
    font-family: Tahoma,Verdana,Arial;
    font-size: 14px;
    margin-bottom: 8vh;
    margin-top: 2vh;
}

.footer-conainter {
    padding-right: 0;
    margin-right: 0;
    text-align: center;
    font-size: 12px;
    color: #b9b9b9;
    line-height: 1.42857;
    background-color: #373536;
    margin: 2%;
    padding: 3%;
    padding-top: 2%;
    padding-bottom: 1%;
}

.footer-links a {
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    margin: 10px;
    text-decoration: none;
    font-size: 12px;
}

.footer-links {
    margin-bottom: 25px;
}